<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_4" x1="25.51" x2="157.49" y1="91.5" y2="91.5">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <g id="icon-studie-xtra">
      <rect :fill="background" height="183" width="183"/>
      <path d="M133,102.65v20.06a15,15,0,0,1-15,15H65a15,15,0,0,1-15-15V102.35l33.12,17.34a15.62,15.62,0,0,0,16,.3ZM90.37,68.59,157,80.37a4.11,4.11,0,0,0-.94-5,6.76,6.76,0,0,0-1.3-1l-59.37-28a8.24,8.24,0,0,0-8.11,0S31.71,71.72,28.08,73.76s-3.2,6.26,0,8.11L50,93.36l37.28,19.51c1.62,1.35,5.35,1.66,8.11,0l37.5-19.18,6.85-3.5V132a4,4,0,0,0,8,0V86.86l-1.1-.2-6.88-1.21-12.07-2.13h0l-20.4-3.6L89,76.47a4,4,0,0,1,1.4-7.88Z" style="fill:url(#Naamloos_verloop_4);"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>